@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap');

body {
  font-family: 'Fira Sans', sans-serif;
}

html,
body,
#root {
  height: 100%;
}
